import React from "react"
import Dashboard from "../../components/ProjectList"
import Seo from "../../components/seo"

const IndexPage = props => {
  return (
    <>
      <Seo title="Project List" />
      <Dashboard {...props} />
    </>
  )
}

export default IndexPage
